.download-btn {
  text-decoration: none;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 15px;
  height: 75px;
  width: 218px;
  display: inline-block;
  padding: 8px 24px 10px 46px;
  border-radius: 3px;
  transition: 0.5s;
  position: relative;

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  // .ios-btn {
  //   background: url('./assets/images/app_store_badge.svg') 0% 0% / contain no-repeat;
  //   background-size: 100% 70%;
  //   background-position: center;
  // }

  // .android-btn {
  //   background: url(https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png) 0% 0% / contain no-repeat;
  // }
}
