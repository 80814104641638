body[data-bs-theme=dark] .work .media::after {
  border-color: #ffffff;
}

body[data-bs-theme=dark] .work .media::before {
  border-color: #ffffff;
}

.work .work-pra ul {
  padding: 0;
}

.work .work-pra ul li {
  padding-top: 10px;
}

.work .work-pra ul li svg {
  float: left;
  font-size: 34px;
  margin-right: 20px;
}

.work .work-pra ul li:hover i {
  color: #656fe0;
}

body[data-bs-theme=dark] .work {
  background-color: #283039 !important;
  color: #ffffff;
}

.testimonial-content {
  position: relative;
  padding: 45% 0px;
}

.testimonial-right {
  position: absolute;
  right: 90px;
  top: 90px;
}

.testimonial-circle {
  position: relative;
}

.testimonial-circle .media {
  width: 297px;
  height: 297px;
  position: relative;
}

.testimonial-circle .media:before {
  content: "";
  position: absolute;
  border: 1px dashed #838485;
  border-radius: 50%;
  width: 126%;
  height: 126%;
  left: -13%;
  top: -13%;
}

.testimonial-circle .media:after {
  content: "";
  position: absolute;
  border: 1px dashed #838485;
  border-radius: 50%;
  width: 160%;
  height: 160%;
  left: -30%;
  top: -30%;
}

.testimonial-circle .media img {
  width: auto;
}

.testimonial-circle span {
  border-radius: 50%;
  background: #656fe0;
  display: inline-block;
}

.testimonial-circle span img {
  border-radius: 50%;
}

.testimonial-circle span.ring1 {
  position: absolute;
  bottom: 30px;
  left: -100px;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.2);
}

.testimonial-circle span.ring1 img {
  padding: 3px;
  width: 80px;
  height: 80px;
  width: 52px;
  height: 52px;
  background-color: #fff;
}

.testimonial-circle span.ring2 {
  position: absolute;
  top: 10px;
  left: -30px;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.2);
}

.testimonial-circle span.ring2 img {
  padding: 3px;
  width: 80px;
  height: 80px;
  width: 54px;
  height: 54px;
  background-color: #656fe0;
}

.testimonial-circle span.ring3 {
  position: absolute;
  right: -100px;
  bottom: 0px;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.2);
}

.testimonial-circle span.ring3 img {
  padding: 3px;
  width: 80px;
  height: 80px;
  width: 76px;
  height: 76px;
  background-color: #fff;
}

.testimonial-circle span.ring4 {
  position: absolute;
  bottom: -70px;
  left: 50%;
  margin-left: -33px;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.2);
}

.testimonial-circle span.ring4 img {
  padding: 3px;
  width: 80px;
  height: 80px;
  width: 66px;
  height: 66px;
  background-color: #656fe0;
}

.testimonial-circle span.ring5 {
  position: absolute;
  top: -90px;
  right: -30px;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.2);
}

.testimonial-circle span.ring5 img {
  padding: 3px;
  width: 80px;
  height: 80px;
  width: 86px;
  height: 86px;
  background-color: #656fe0;
}

.testimonial-circle span.ring6 {
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: #656fe0;
  right: 10px;
  bottom: -70px;
}

.testimonial-circle span.ring7 {
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: #656fe0;
  top: 50%;
  left: -50px;
}

.testimonial-circle span.ring8 {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #656fe0;
  right: -44px;
  top: 40%;
}

.animate-v {
  animation: upNdown1 2s infinite linear;
}

.animate-v2 {
  animation: upNdown11 2s infinite linear;
}

.animate-v3 {
  animation: upNdown12 2s infinite linear;
}

@media only screen and (max-width: 991px) {
  .testimonial-circle {
    display: none;
  }

  .testimonial-circle .media {
    margin-top: 50px;
    width: 120px;
    height: 120px;
    position: relative;
    transition: all 0.5s ease;
    display: none;
  }

  .testimonial-circle span.ring1 {
    bottom: 5px;
    left: -48px;
  }

  .testimonial-circle span.ring1 img {
    width: 32px;
    height: 32px;
  }

  .testimonial-circle span.ring2 {
    top: 10px;
    left: -30px;
  }

  .testimonial-circle span.ring2 img {
    width: 34px;
    height: 34px;
  }

  .testimonial-circle span.ring3 {
    right: -60px;
    bottom: 0px;
  }

  .testimonial-circle span.ring3 img {
    width: 56px;
    height: 56px;
  }

  .testimonial-circle span.ring4 {
    bottom: -65px;
    left: 50%;
    margin-left: -33px;
  }

  .testimonial-circle span.ring4 img {
    width: 46px;
    height: 46px;
  }

  .testimonial-circle span.ring5 {
    top: -60px;
    right: -30px;
  }

  .testimonial-circle span.ring5 img {
    width: 66px;
    height: 66px;
  }

  .testimonial-circle span.ring6 {
    width: 16px;
    height: 16px;
    right: 10px;
    bottom: -70px;
  }

  .testimonial-circle span.ring7 {
    width: 8px;
    height: 8px;
    top: 50%;
    left: -50px;
  }

  .testimonial-circle span.ring8 {
    width: 4px;
    height: 4px;
    right: -44px;
    top: 40%;
  }

  .testimonial-content {
    position: relative;
    padding: 0px 0px;
    margin-bottom: 10px;
  }
}