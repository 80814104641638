.pricing .pricing-box {
  transition: all 0.5s ease;
  border-radius: 15px;
  border: 1px solid rgba(101, 111, 224, 0.3);
}
.pricing .pricing-box .pricing-icon {
  position: relative;
  z-index: 0;
}
.pricing .pricing-box .pricing-icon h3 {
  font-size: 34px;
}
.pricing .pricing-box .pricing-icon::before {
  content: "";
  position: absolute;
  background-image: url("../images/bg-1.png");
  background-size: cover;
  width: 140px;
  height: 140px;
  z-index: -1;
  top: -53px;
  left: 50%;
  transform: translateX(-50%);
}
.pricing .pricing-box .box-heading {
  transition: all 1s ease;
}
.pricing .pricing-box .pricing-team .pricing-item {
  margin: 10px 0;
  font-size: 16px;
}
.pricing .pricing-box .pricing-team .pricing-item i {
  font-size: 20px;
  margin-right: 6px;
}
.pricing .pricing-box:hover {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 7px 29px 0px;
}
.pricing .pricing-box:hover {
  transform: scale(1.04);
}

@media (min-width: 992px) {
  .pricing-box.pricing-active {
    transform: scale(1.04);
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 7px 29px 0px;
    background-color: #ffffff !important;
  }
  .pricing-box.pricing-active .box-heading {
    color: #000000;
  }
  .pricing-box.pricing-active .btn {
    color: #000000;
  }
  .pricing-box.pricing-active .pricing-team .pricing-item {
    color: #17212a;
  }
}
@media (max-width: 768px) {
  .bg-pricing {
    height: auto;
    padding: 90px 0;
  }
}
body[data-bs-theme=dark] .pricing {
  background-color: #17212a !important;
  color: #ffffff;
}