.contact {
  padding: 120px 0 76px 0;
}
.contact .contact-map {
  position: relative;
  z-index: 999;
}
.contact .contact-form {
  margin-bottom: 15px;
  box-shadow: none !important;
  height: 54px;
  padding-left: 20px;
  font-size: 15px;
  border-radius: 5px;
  z-index: 1;
  color: #17212a;
}
.contact .custom-form .form-control:focus {
  border-color: #a3a3a3;
}
.contact .form-section {
  z-index: 1;
  position: relative;
  bottom: -100px;
}

body[data-bs-theme=dark] .contact {
  background-color: #17212a !important;
  color: #ffffff;
}
body[data-bs-theme=dark] .contact .contact-form {
  border: 1px solid #1e2932;
  background-color: #1e2932;
  color: #ffffff;
}

.map {
  filter: grayscale(100%) invert(92%) contrast(83%);
}