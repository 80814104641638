
.app-slider {
  cursor: alias;
  padding-bottom: 40px;
}
.app-slider .tobii-zoom__icon {
  display: none;
}
.app-slider .swiper-container {
  padding: 18px 0 50px 0;
}
.app-slider .swiper-container .fream-phone {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 0;
  height: auto;
  width: 230px;
}
.app-slider .swiper-container .swiper-wrapper img {
  border-radius: 20px;
}
.app-slider .swiper-container .swiper-pagination-bullet-active {
  opacity: 1;
  background: #656fe0;
}
.app-slider img {
  width: 100%;
}

@media (max-width: 1200px) {
  .app-slider .swiper-container {
    padding: 15px 0 50px 0;
  }
  .app-slider .swiper-container .fream-phone {
    width: 180px;
  }
}
@media (max-width: 992px) {
  .testimonial #testi-slider-iw {
    margin: 0 !important;
  }
  .app-slider .swiper-container {
    padding: 15px 0 50px 0;
  }
  .app-slider .swiper-container .fream-phone {
    width: 260px;
  }
}
@media (max-width: 768px) {
  .fream-phone {
    display: none;
  }
}
@media (max-width: 1024px) {
  .screenshot-img img {
    height: 285px;
  }
}
body[data-bs-theme=dark] .app-slider {
  background-color: #283039 !important;
  color: #ffffff;
}
body[data-bs-theme=dark] .work .media::after {
  border-color: #ffffff;
}
body[data-bs-theme=dark] .work .media::before {
  border-color: #ffffff;
}