.home-1 {
  background-size: cover;
  align-self: center;
  position: relative;
  padding-top: 76px;
  background-position: center center;
  background-image: url("../images/home-bg.png");
}

.home-1 .home-heading .home-title {
  font-size: 40px;
  font-weight: 400;
}

.home-1 .home-heading .para-desc {
  max-width: 600px;
  margin: 0 auto;
}

.home-1 .home-heading ul li a {
  display: flex;
  align-items: center;
}

.home-1 .home-heading ul li a i {
  font-size: 17px;
}

.home-1 .home-img .light-img {
  display: block;
}

.home-1 .home-img .dark-img {
  display: none;
}

.home-1 .home-circle {
  margin-bottom: 74px;
}

.home-1 .home-circle img {
  width: auto;
}

.home-1 span.ring1 {
  position: absolute;
  bottom: 30%;
  left: 100px;
}

.home-1 span.ring1 img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #838485;
}

.home-1 span.ring2 {
  position: absolute;
  top: 10px;
  left: 20%;
}

.home-1 span.ring2 img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid #656fe0;
}

.home-1 span.ring3 {
  position: absolute;
  right: 84px;
  bottom: 35%;
}

.home-1 span.ring3 img {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  border: 2px solid #838485;
}

.home-1 span.ring4 {
  position: absolute;
  bottom: 15%;
  left: 46%;
}

.home-1 span.ring4 img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 2px solid #656fe0;
}

.home-1 span.ring5 {
  position: absolute;
  top: -30px;
  right: 20%;
}

.home-1 span.ring5 img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #656fe0;
}

.home-1 .animate-v {
  animation: upNdown1 2s infinite linear;
}

.home-1 .animate-curve:after {
  animation: upNdown2 2s infinite linear;
  animation: upNdown2 2s infinite linear;
}

.home-1 .animate-v2 {
  animation: upNdown11 2s infinite linear;
}

.home-1 .animate-v3 {
  animation: upNdown12 2s infinite linear;
}

/* Animate Up Down*/
@keyframes upNdown1 {
  50% {
    transform: translateY(40px);
  }
}

@keyframes upNdown2 {
  50% {
    transform: translateY(20px);
  }
}

@keyframes upNdown2 {
  50% {
    transform: translateY(20px);
  }
}

/* Animate Up Down 2*/
@keyframes upNdown11 {
  50% {
    transform: translateY(10px);
  }
}

/* Animate Up Down 2*/
@keyframes upNdown12 {
  50% {
    transform: translateY(-10px);
  }
}

@media (max-width: 576px) {
  .home-circle {
    margin-bottom: 0px !important;
  }

  .home-circle .ring1 {
    display: none;
  }

  .home-circle .ring2 {
    display: none;
  }

  .home-circle .ring3 {
    display: none;
  }

  .home-circle .ring4 {
    display: none;
  }

  .home-circle .ring5 {
    display: none;
  }
}

body[data-bs-theme=dark] .home-1 {
  background-color: #17212a !important;
  color: #ffffff;
}

body[data-bs-theme=dark] .home-1 .home-img .light-img {
  display: none;
}

body[data-bs-theme=dark] .home-1 .home-img .dark-img {
  display: block;
}