.features {
  padding-bottom: 100px;
}
.features .features-box:hover .features-icon {
  background-color: rgba(101, 111, 224, 0.2);
  border-radius: 50%;
  border: 2px solid #656fe0;
}
.features .features-box .features-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  line-height: 1;
  color: #656fe0;
  transition: all 0.3s ease;
}
.features .features-box .features-icon svg {
  font-size: 34px;
  font-weight: 200;
}

body[data-bs-theme=dark] .features {
  background-color: #17212a !important;
  color: #ffffff;
}